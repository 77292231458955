import { render, staticRenderFns } from "./CustomInputComp.vue?vue&type=template&id=a405a320&scoped=true&"
import script from "./CustomInputComp.vue?vue&type=script&lang=js&"
export * from "./CustomInputComp.vue?vue&type=script&lang=js&"
import style0 from "./CustomInputComp.vue?vue&type=style&index=0&id=a405a320&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a405a320",
  null
  
)

export default component.exports